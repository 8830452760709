<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Penerapan Perizinan Berusaha</div>
                  <div class="wizard-desc">Penerapan Perizinan Berusaha</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Penyederhanaan Persyaratan Dasar Perizinan Berusaha
                  </div>
                  <div class="wizard-desc">
                    Penyederhanaan Persyaratan Dasar Perizinan Berusaha
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Peningkatan Iklim Investasi</div>
                  <div class="wizard-desc">Peningkatan Iklim Investasi</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-12 px-0 py-lg-15 px-lg-0">
              <div class="col-xl-12 col-xxl-11">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-12" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penerapan Perizinan
                          Berusaha berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>

                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Penyusunan dan atau revisi peraturan daerah
                                dan/atau peraturan kepala daerah</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada Perda atau Perkada dalam dokumen
                              Prolegda ?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penyusunan Perda dan Perkada berdasarkan Pasal
                                  4 ayat (1), Pasal 5 ayat (1), dan Pasal 39
                                  ayat (1) PP No. 6 Tahun 2021 tentang
                                  Penyelenggaraan Perizinan Berusaha di Daerah,
                                  Pemda wajib menyesuaikan Perda dan Perkada
                                  paling lama 2 (dua) bulan terhitung sejak PP
                                  No. 6 Tahun 2021 diundangkan. Penilaian
                                  keberadaan Perkada dalam program pembentukan
                                  peraturan kepala daerah (Propemperkada)
                                  dilakukan dengan ketentuan Perkada tentang
                                  penyelenggaraan perizinan berusaha berbasis
                                  risiko terdapat dalam Propemperkada
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1a1_nilai',
                                      dataVerlap.p1a1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1a1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p1a1_nilai"
                                  @change="
                                    submitQC('p1a1_nilai', dataQC.p1a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5  ">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian konsultasi pengusulan
                              Perkada dengan Organisasi Perangkat Daerah (OPD)
                              teknis dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1a2_nilai',
                                      dataVerlap.p1a2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1a2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a2_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p1a2_nilai"
                                  @change="
                                    submitQC('p1a2_nilai', dataQC.p1a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1a3_nilai',
                                      dataVerlap.p1a3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1a3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p1a3_nilai"
                                  @change="
                                    submitQC('p1a3_nilai', dataQC.p1a3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada Koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian koordinasi pengusulan
                              Perkada dengan Kementerian Dalam Negeri,
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1a4_nilai',
                                      dataVerlap.p1a4_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1a4_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a4_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p1a4_nilai"
                                  @change="
                                    submitQC('p1a4_nilai', dataQC.p1a4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digita
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">5</td>
                            <td rowspan="2">
                              Apakah ada dokumen Peraturan daerah atau Peraturan
                              Kepala Daerah terkait penerapan perizinan berusaha
                              berbasis risiko?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen Perda/Perkada Tentang Perizinan
                                  Berusaha Berbasis Risiko atau Perkada
                                  Pelimpahan Wewenang PB yang merujuk pada PP 5
                                  dan PP 6 Tahun 2021
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a5_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options3"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options3">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1a5_nilai',
                                      dataVerlap.p1a5_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1a5_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a5_nilai_status
                                    ),
                                  }"
                                  :options="options3"
                                  v-model="dataQC.p1a5_nilai"
                                  @change="
                                    submitQC('p1a5_nilai', dataQC.p1a5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, sudah disahkan” apabila
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    ketentuan di atas tidak terpenuhi namun
                                    sudah tersedia dalam bentuk naskah/dokumen
                                    usulan. <br />
                                    3. Nilai “Belum ada” apabila ketentuan di
                                    atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait penerapam perizinan berusaha berbasis
                              risiko?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian ada tidaknya sosialisasi
                              Perkada terkait penerapan perizinan berusaha
                              berbasis risiko dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a6_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a6_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options4"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a6_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options4">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1a6_nilai',
                                      dataVerlap.p1a6_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1a6_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a6_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a6_nilai_status
                                    ),
                                  }"
                                  :options="options4"
                                  v-model="dataQC.p1a6_nilai"
                                  @change="
                                    submitQC('p1a6_nilai', dataQC.p1a6_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a6_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a6_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a6_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a6_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a6_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a6_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a6_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a6_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, hanya 1 kali” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Verifikasi</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Berapa persen verifikasi teknis dan notifikasi
                              pemenuhan standar dan persyaratan Perizinan
                              Berusaha Berbasis Risiko?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen bukti perlohonan risiko MT yang masuk
                                  dan verifikasinya di sistem OSS
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1b1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options5"
                                  style="min-width: 150px"
                                  v-model="datapm.p1b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options5">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1b1_nilai',
                                      dataVerlap.p1b1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1b1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1b1_nilai_status
                                    ),
                                  }"
                                  :options="options5"
                                  v-model="dataQC.p1b1_nilai"
                                  @change="
                                    submitQC('p1b1_nilai', dataQC.p1b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen bukti permohonan risiko Tinggi (T)
                                  yang masuk
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1b1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian verifikasi teknis dan notifikasi
                                    pemenuhan standar dan persyaratan Perizinan
                                    Berusaha Berbasis Risiko dilakukan dengan
                                    ketentuan: <br />
                                    1. Verifikasi teknis dan notifikasi
                                    pemenuhan standar oleh pemerintah daerah
                                    terhadap permohonan perizinan berusaha
                                    dengan tingkat risiko Menengah Tinggi (MT)
                                    yang menjadi kewenangannya.<br />
                                    2. Verifikasi teknis dan notifikasi
                                    pemenuhan persyaratan oleh pemerintah daerah
                                    terhadap permohonan perizinan berusaha
                                    dengan tingkat risiko Tinggi (T) yang
                                    menjadi kewenangannya. <br />
                                    3. Penilaian verifikasi teknis dan
                                    notifikasi pemenuhan standar dan persyaratan
                                    Perizinan Berusaha Berbasis Risiko dilakukan
                                    dengan menghitung perbandingan jumlah
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko MT dan T yang telah diverifikasi
                                    dengan total permohonan perizinan berusaha
                                    yang masuk ke dalam dashboard OSS Pemda
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada monitoring dan evaluasi implementasi
                              penyusunan Perda/Perkada?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 4
                              ayat (1), Pasal 5 ayat (1), dan Pasal 39 ayat (1)
                              PP No. 6 Tahun 2021 tentang Penyelenggaraan
                              Perizinan Berusaha di Daerah, Pemda wajib
                              menyesuaikan Perda dan Perkada paling lama 2 (dua)
                              bulan terhitung sejak PP No. 6 Tahun 2021
                              diundangkan. Penilaian ada tidaknya monitoring dan
                              evaluasi implementasi penyusunan perkada dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1c1_nilai',
                                      dataVerlap.p1c1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1c1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c1_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p1c1_nilai"
                                  @change="
                                    submitQC('p1c1_nilai', dataQC.p1c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat notula kegiatan monitoring dan
                                  evaluasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan kinerja implementasi OSS ?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan kinerja
                                  implementasi OSS dilakukan dengan ketentuan
                                  apabila laporan kinerja implementasi OSS
                                  dibuat secara formal dalam bentuk dokumen
                                  laporan lengkap fisik atau digital, bukan
                                  bahan presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p1c2_nilai',
                                      dataVerlap.p1c2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p1c2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p1c2_nilai"
                                  @change="
                                    submitQC('p1c2_nilai', dataQC.p1c2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_1_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_1_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_1_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_1_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penyederhanaan
                          Persyaratan Dasar Perizinan Berusaha berdasarkan fakta
                          yang ada dengan memilih data yang sesuai. Dokumen
                          lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau peraturan kepala daerah
                                terkait penyusunan Rencana Detil Tata Ruang
                                Digital</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada Perda atau Perkada dalam dokumen
                              Prolegda?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian Keberadaan Perda dalam dokumen program
                              pembentukan peraturan daerah (Propemperda) dan
                              Perkada dalam program pembentukan peraturan kepala
                              daerah (Propemperkada) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Perda mengenai Rencana Tata Ruang Wilayah
                                  (RTRW)
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2a1_nilai',
                                      dataVerlap.p2a1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2a1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p2a1_nilai"
                                  @change="
                                    submitQC('p2a1_nilai', dataQC.p2a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Perkada mengenai Rencana Detail Tata Ruang
                                  (RDTR
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 32 ayat (2), dan Pasal 39 ayat (1)
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, Pemda wajib menyesuaikan Perda dan
                                      Perkada paling lama 2 (dua) bulan
                                      terhitung sejak PP No. 6 Tahun 2021
                                      diundangkan. Perda dan Perkada dimaksud
                                      adalah:
                                    </div>
                                    <div class="col-12">
                                      a. Perda mengenai Rencana Tata Ruang
                                      Wilayah (RTRW)
                                    </div>
                                    <div class="col-12">
                                      b. Perkada mengenai Rencana Detail Tata
                                      Ruang (RDTR)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Untuk pemerintah provinsi, nilai “Ada”
                                    apabila dapat menunjukkan naskah/dokumen
                                    usulan Perda mengenai Rencana Tata Ruang
                                    Wilayah (RTRW) dalam dokumen Propemperda.<br />
                                    2. Untuk pemerintah kabupaten/kota, nilai
                                    “Ada” apabila dapat menunjukkan
                                    naskah/dokumen usulan Perkada mengenai
                                    Rencana Detail Tata Ruang (RDTR) dalam
                                    dokumen Propemperkada. <br />
                                    3. Nilai “Tidak ada” apabila pemerintah
                                    provinsi tidak dapat memenuhi ketentuan
                                    nomor 1 dan pemerintah kabupaten/kota tidak
                                    dapat memenuhi ketentuan nomor 1 dan 2.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="7" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Organisasi Perangkat Daerah (OPD) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2a2_nilai',
                                      dataVerlap.p2a2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2a2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a2_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2a2_nilai"
                                  @change="
                                    submitQC('p2a2_nilai', dataQC.p2a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 32 ayat (2), dan Pasal 39 ayat (1)
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, Pemda wajib menyesuaikan Perda dan
                                      Perkada paling lama 2 (dua) bulan
                                      terhitung sejak PP No. 6 Tahun 2021
                                      diundangkan. Perda dan Perkada dimaksud
                                      adalah:
                                    </div>
                                    <div class="col-12">
                                      a. Perda mengenai Rencana Tata Ruang
                                      Wilayah (RTRW)
                                    </div>
                                    <div class="col-12">
                                      b. Perkada mengenai Rencana Detail Tata
                                      Ruang (RDTR)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya yang
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2a3_nilai',
                                      dataVerlap.p2a3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2a3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2a3_nilai"
                                  @change="
                                    submitQC('p2a3_nilai', dataQC.p2a3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 32 ayat (2), dan Pasal 39 ayat (1)
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, Pemda wajib menyesuaikan Perda dan
                                      Perkada paling lama 2 (dua) bulan
                                      terhitung sejak PP No. 6 Tahun 2021
                                      diundangkan. Perda dan Perkada dimaksud
                                      adalah
                                    </div>
                                    <div class="col-12">
                                      a. Perda mengenai Rencana Tata Ruang
                                      Wilayah (RTRW)
                                    </div>
                                    <div class="col-12">
                                      b. Perkada mengenai Rencana Detail Tata
                                      Ruang (RDTR)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2a4_nilai',
                                      dataVerlap.p2a4_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2a4_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a4_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2a4_nilai"
                                  @change="
                                    submitQC('p2a4_nilai', dataQC.p2a4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 32 ayat (2), dan Pasal 39 ayat (1)
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, Pemda wajib menyesuaikan Perda dan
                                      Perkada paling lama 2 (dua) bulan
                                      terhitung sejak PP No. 6 Tahun 2021
                                      diundangkan. Perda dan Perkada dimaksud
                                      adalah
                                    </div>
                                    <div class="col-12">
                                      a. Perda mengenai Rencana Tata Ruang
                                      Wilayah (RTRW)
                                    </div>
                                    <div class="col-12">
                                      b. Perkada mengenai Rencana Detail Tata
                                      Ruang (RDTR)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">5</td>
                            <td rowspan="3">
                              Apakah ada dokumen Peraturan Daerah atau Peraturan
                              Kepala Daerah terkait penyusunan RDTR Digital?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Perda RTRW</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a5_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options3"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options3">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2a5_nilai',
                                      dataVerlap.p2a5_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2a5_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a5_nilai_status
                                    ),
                                  }"
                                  :options="options3"
                                  v-model="dataQC.p2a5_nilai"
                                  @change="
                                    submitQC('p2a5_nilai', dataQC.p2a5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Perkada DRDTR Digital</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a5_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a5_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 32 ayat (2), dan Pasal 39 ayat (1)
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, Pemda wajib menyesuaikan Perda dan
                                      Perkada paling lama 2 (dua) bulan
                                      terhitung sejak PP No. 6 Tahun 2021
                                      diundangkan. Perda dan Perkada dimaksud
                                      adalah
                                    </div>
                                    <div class="col-12">
                                      a. Perda mengenai Rencana Tata Ruang
                                      Wilayah (RTRW)
                                    </div>
                                    <div class="col-12">
                                      b. Perkada mengenai Rencana Detail Tata
                                      Ruang (RDTR)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, sudah disahkan” apabila salah
                                    satu ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    seluruh ketentuan di atas tidak terpenuhi
                                    namun sudah tersedia dalam bentuk
                                    naskah/dokumen usulan. <br />
                                    3. Nilai “Belum ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="8">6</td>
                            <td rowspan="8">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait penyusunan RDTR Digital?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian ada tidaknya sosialisasi Perda atau
                              Perkada terkait penyusunan RDTR dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a6_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a6_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="6" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options4"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a6_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="6" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options4">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2a6_nilai',
                                      dataVerlap.p2a6_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2a6_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="6" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a6_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a6_nilai_status
                                    ),
                                  }"
                                  :options="options4"
                                  v-model="dataQC.p2a6_nilai"
                                  @change="
                                    submitQC('p2a6_nilai', dataQC.p2a6_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a6_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a6_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a6_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a6_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a6_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a6_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a6_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a6_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a6_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan sosialisasi dipublikasikan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a6_file6" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a6_file6 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 32 ayat (2), dan Pasal 39 ayat (1)
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, Pemda wajib menyesuaikan Perda dan
                                      Perkada paling lama 2 (dua) bulan
                                      terhitung sejak PP No. 6 Tahun 2021
                                      diundangkan. Perda dan Perkada dimaksud
                                      adalah
                                    </div>
                                    <div class="col-12">
                                      a. Perda mengenai Rencana Tata Ruang
                                      Wilayah (RTRW)
                                    </div>
                                    <div class="col-12">
                                      b. Perkada mengenai Rencana Detail Tata
                                      Ruang (RDTR)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau Peraturan kepala Daerah
                                terkait persetujuan lingkungan dan
                                pengawasannya</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada Perda dan atau Perkada di dalam dokumen
                              Prolegda?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Penilaian keberadaan Perkada dalam program
                                  pembentukan peraturan kepala daerah
                                  (Propemperkada) dilakukan dengan ketentuan
                                  <div class="row">
                                    <div class="col-1">a.</div>
                                    <div class="col-11">
                                      Kepala daerah menerbitkan peraturan kepala
                                      daerah mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Kepala DPMPTSP
                                    </div>
                                    <div class="col-1">b.</div>
                                    <div class="col-11">
                                      Kepala daerah menerbitkan peraturan kepala
                                      daerah mengenai pendelegasian kewenangan
                                      penyelenggaraan persetujuan lingkungan
                                      kepada Dinas Lingkungan Hidup
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2b1_nilai',
                                      dataVerlap.p2b1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2b1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p2b1_nilai"
                                  @change="
                                    submitQC('p2b1_nilai', dataQC.p2b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Keberadaan Perkada berdasarkan Pasal 1 angka
                                    4 PP No. 22 Tahun 2021 tentang
                                    Penyelenggaraan Perlindungan dan Pengelolaan
                                    Lingkungan Hidup, Persetujuan Lingkungan
                                    adalah Keputusan Kelayakan Lingkungan Hidup
                                    atau Pernyataan Kesanggupan Pengelolaan
                                    Lingkungan Hidup yang telah mendapatkan
                                    persetujuan dari pemerintah pusat atau
                                    pemerintah daerah.
                                  </p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila salah satu ketentuan
                                    di atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau Pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian konsultasi pengusulan
                              Perkada dengan OPD teknis dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2b2_nilai',
                                      dataVerlap.p2b2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2b2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b2_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2b2_nilai"
                                  @change="
                                    submitQC('p2b2_nilai', dataQC.p2b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau Pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian konsultasi pengusulan
                              Perkada dengan Kementerian Dalam Negeri,
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2b3_nilai',
                                      dataVerlap.p2b3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2b3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2b3_nilai"
                                  @change="
                                    submitQC('p2b3_nilai', dataQC.p2b3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementrian Dalam Negeri, Kementrian
                              Investasi, dan Kementrian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau Pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian koordinasi pengusulan
                              Perkada dengan Kementerian Dalam Negeri,
                              Kementerian Investasi/BKPM dan Kementerian Teknis
                              lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2b4_nilai',
                                      dataVerlap.p2b4_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2b4_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b4_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2b4_nilai"
                                  @change="
                                    submitQC('p2b4_nilai', dataQC.p2b4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">5</td>
                            <td rowspan="2">
                              Apakah ada dokumen Peraturan Daerah atau Peraturan
                              Kepala Daerah terkait pengawasan dan perizinan
                              lingkungan?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b5_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options3"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options3">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2b5_nilai',
                                      dataVerlap.p2b5_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2b5_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b5_nilai_status
                                    ),
                                  }"
                                  :options="options3"
                                  v-model="dataQC.p2b5_nilai"
                                  @change="
                                    submitQC('p2b5_nilai', dataQC.p2b5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Keberadaan Perkada berdasarkan Pasal 1
                                      angka 4 PP No. 22 Tahun 2021 tentang
                                      Penyelenggaraan Perlindungan dan
                                      Pengelolaan Lingkungan Hidup, Persetujuan
                                      Lingkungan adalah Keputusan Kelayakan
                                      Lingkungan Hidup atau Pernyataan
                                      Kesanggupan Pengelolaan Lingkungan Hidup
                                      yang telah mendapatkan persetujuan dari
                                      pemerintah pusat atau pemerintah daerah.
                                      Penilaian ada tidaknya Peraturan Kepala
                                      Daerah terkait pengawasan dan perizinan
                                      lingkungan dilakukan dengan ketentuan
                                    </div>
                                    <div class="col-12">
                                      1. Kepala daerah menerbitkan peraturan
                                      kepala daerah mengenai pendelegasian
                                      kewenangan penyelenggaraan persetujuan
                                      lingkungan kepada Kepala DPMPTSP dalam
                                      lembaran daerah
                                    </div>
                                    <div class="col-12">
                                      2. Kepala daerah menerbitkan peraturan
                                      kepala daerah mengenai pendelegasian
                                      kewenangan penyelenggaraan persetujuan
                                      lingkungan kepada Dinas Lingkungan Hidup
                                      dalam lembaran daerah
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, sudah disahkan” apabila salah
                                    satu ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    seluruh ketentuan di atas tidak terpenuhi
                                    namun sudah tersedia dalam bentuk
                                    naskah/dokumen usulan. <br />
                                    3. Nilai “Belum ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada Sosialisasi Perda atau Perkada terkait
                              pengawasan dan perizinan lingkungan?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Keberadaan Perkada berdasarkan Pasal 1 angka 4 PP
                              No. 22 Tahun 2021 tentang Penyelenggaraan
                              Perlindungan dan Pengelolaan Lingkungan Hidup,
                              Persetujuan Lingkungan adalah Keputusan Kelayakan
                              Lingkungan Hidup atau pernyataan Kesanggupan
                              Pengelolaan Lingkungan Hidup yang telah
                              mendapatkan persetujuan dari pemerintah pusat atau
                              pemerintah daerah. Penilaian ada tidaknya
                              sosialisasi Perkada terkait pengawasan dan
                              perizinan lingkungan dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b6_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b6_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options4"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b6_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options4">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2b6_nilai',
                                      dataVerlap.p2b6_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2b6_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b6_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b6_nilai_status
                                    ),
                                  }"
                                  :options="options4"
                                  v-model="dataQC.p2b6_nilai"
                                  @change="
                                    submitQC('p2b6_nilai', dataQC.p2b6_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b6_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b6_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b6_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b6_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b6_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b6_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b6_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b6_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau Peraturan kepala daerah
                                terkait persetujuan bangunan gedung melalui
                                sistem elektronik, pengawasan pelaksanaan
                                konstruksi bangunan Gedung, dan pembinaan
                                bangunan Gedung</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada Perda atau Perkada dalam dokumen
                              Prolegda?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Penilaian keberadaan Perda dalam program
                                  pembentukan peraturan daerah (Propemperda)
                                  dan/atau Perkada dalam program pembentukan
                                  peraturan kepala daerah (Propemperkada)
                                  dilakukan dengan ketentuan
                                  <div class="row">
                                    <div class="col-1">a.</div>
                                    <div class="col-11">
                                      Dokumen Perda mengenai Retribusi
                                      Persetujuan Bangunan Gedung dalam
                                      Propemperda; dan/atau
                                    </div>
                                    <div class="col-1">b.</div>
                                    <div class="col-11">
                                      Dokumen Perkada mengenai tata cara
                                      penyelenggaraan PBG dan Sertifikat Laik
                                      Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG) dalam
                                      Propemperkada
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2c1_nilai',
                                      dataVerlap.p2c1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2c1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p2c1_nilai"
                                  @change="
                                    submitQC('p2c1_nilai', dataQC.p2c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                    </div>
                                    <div class="col-12">
                                      1. Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung; dan/atau
                                    </div>
                                    <div class="col-12">
                                      2. Peraturan kepala daerah mengenai tata
                                      cara penyelenggaraan PBG dan Sertifikat
                                      Laik Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila dapat menunjukkan
                                    naskah/dokumen usulan perda mengenai
                                    Retribusi PBG dan Perkada mengenai tata cara
                                    penyelenggaraan PBG dan SLF melalui Sistem
                                    Informasi Manajemen Bangunan Gedung
                                    (SIMBG).<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan Perda dan Perkada
                              dengan OPD daerah?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penyusunan Perda dan Perkada berdasarkan Pasal 347
                              ayat (2) PP No. 16 Tahun 2021 tentang Peraturan
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan OPD dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2c2_nilai',
                                      dataVerlap.p2c2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2c2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c2_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2c2_nilai"
                                  @change="
                                    submitQC('p2c2_nilai', dataQC.p2c2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Pelaksanaan Undang-Undang Nomor 28 Tahun
                                      2002 Tentang Bangunan Gedung, Pemerintah
                                      kabupaten/kota wajib menyediakan
                                      Persetujuan Bangunan Gedung (PBG) paling
                                      lama 6 (enam) bulan terhitung sejak PP No.
                                      16 Tahun 2021 diundangkan melalui
                                      penetapan
                                    </div>
                                    <div class="col-12">
                                      a. Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung; dan/atau
                                    </div>
                                    <div class="col-12">
                                      b. Peraturan kepala daerah mengenai tata
                                      cara penyelenggaraan PBG dan Sertifikat
                                      Laik Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada pengusulan Perda dan Perkada dengan
                              Kementerian Dalam Negeri, Kementerian Investasi,
                              dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2c3_nilai',
                                      dataVerlap.p2c3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2c3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2c3_nilai"
                                  @change="
                                    submitQC('p2c3_nilai', dataQC.p2c3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung; dan/atau
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi, dan Kementerian Teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Koordinasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2c4_nilai',
                                      dataVerlap.p2c4_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2c4_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c4_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2c4_nilai"
                                  @change="
                                    submitQC('p2c4_nilai', dataQC.p2c4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung; dan/atau
                                      </div>
                                      <div class="col-12">
                                        b. peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">5</td>
                            <td rowspan="2">
                              Apakah ada dokumen Peraturan daerah atau Peraturan
                              Kepala Daerah terkait persetujuan bangunan Gedung
                              melalui sistem elektronik, pengawasan pelaksaan
                              konstruksi bangunan Gedung, dan pembinaan bangunan
                              Gedung?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Perkada Tentang PBG dan atau SLF melalui
                                  Sistem Informasi Manajemen Bangunan Gedung
                                  (SIMBG)
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c5_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options3"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options3">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2c5_nilai',
                                      dataVerlap.p2c5_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2c5_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c5_nilai_status
                                    ),
                                  }"
                                  :options="options3"
                                  v-model="dataQC.p2c5_nilai"
                                  @change="
                                    submitQC('p2c5_nilai', dataQC.p2c5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div class="row" style="color: white">
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                    </div>
                                    <div class="col-12">
                                      a. Peraturan daerah mengenai Retribusi
                                      Persetujuan Bangunan Gedung; dan/atau
                                    </div>
                                    <div class="col-12">
                                      b. Peraturan kepala daerah mengenai tata
                                      cara penyelenggaraan PBG dan Sertifikat
                                      Laik Fungsi (SLF) melalui Sistem Informasi
                                      Manajemen Bangunan Gedung (SIMBG)
                                    </div>
                                  </div>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, sudah disahkan” apabila salah
                                    satu ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    menunjukkan naskah usulan Perda mengenai
                                    retribusi penyelenggaraan PBG dan/atau
                                    Perkada mengenai penyelenggaraan PBG. <br />
                                    3. Nilai “Belum ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait persetujuan bangunan Gedung melalu system
                              elektronik, pengawasan pelaksaan konstruksi
                              bangunan Gedung, dan pembinaan bangunan Gedung?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian ada tidaknya sosialisasi Perda atau
                              Perkada terkait persetujuan bangunan gedung
                              melalui sistem elektronik, pengawasan pelaksanaan
                              konstruksi bangunan Gedung, dan pembinaan bangunan
                              Gedung dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c6_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c6_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options4"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c6_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options4">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2c6_nilai',
                                      dataVerlap.p2c6_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2c6_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c6_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c6_nilai_status
                                    ),
                                  }"
                                  :options="options4"
                                  v-model="dataQC.p2c6_nilai"
                                  @change="
                                    submitQC('p2c6_nilai', dataQC.p2c6_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c6_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c6_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c6_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c6_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c6_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c6_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c6_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c6_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung; dan/atau
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Peraturan daerah atau Peraturan kepala Daerah
                                terkait penerbitan Sertifikat Laik Fungsi
                                melalui sistem elektronik</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada Perda dan/atau Perkada dalam dokumen
                              Prolegda?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian keberadaan Perda dan atau Perkada
                              dokumen program pembentukan peraturan daerah
                              (Propemperda) atau program pembentukan peraturan
                              kepala daerah (Propemperkada) dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen Perda mengenai Retribusi Persetujuan
                                  Bangunan Gedung dalam Propemperda
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2d1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2d1_nilai',
                                      dataVerlap.p2d1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2d1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2d1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2d1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p2d1_nilai"
                                  @change="
                                    submitQC('p2d1_nilai', dataQC.p2d1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen Perkada mengenai tata cara
                                  penyelenggaraan PBG dan Sertifikat Laik Fungsi
                                  (SLF) melalui Sistem Informasi Manajemen
                                  Bangunan Gedung (SIMBG) dalam Propemperkada
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        1. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung
                                      </div>
                                      <div class="col-12">
                                        2. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada” apabila seluruh ketentuan
                                      di atas terpenuhi.<br />
                                      2. Nilai “Tidak ada” apabila ketentuan di
                                      atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada konsultasi pengusulan Perda dan Perkada
                              dengan OPD Daerah?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan OPD dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2d2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2d2_nilai',
                                      dataVerlap.p2d2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2d2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2d2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2d2_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2d2_nilai"
                                  @change="
                                    submitQC('p2d2_nilai', dataQC.p2d2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung; dan/atau
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan kementerian teknis lainnya ?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian konsultasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2d3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2d3_nilai',
                                      dataVerlap.p2d3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2d3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2d3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2d3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2d3_nilai"
                                  @change="
                                    submitQC('p2d3_nilai', dataQC.p2d3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung; dan/atau
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi dan kementerian teknis lainnya?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian koordinasi pengusulan Perda dan Perkada
                              dengan Kementerian Dalam Negeri, Kementerian
                              Investasi/BKPM dan Kementerian Teknis lainnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2d4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options2">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2d4_nilai',
                                      dataVerlap.p2d4_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2d4_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2d4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2d4_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  v-model="dataQC.p2d4_nilai"
                                  @change="
                                    submitQC('p2d4_nilai', dataQC.p2d4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung dan atau
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">5</td>
                            <td rowspan="3">
                              Apakah ada dokumen Peraturan Daerah atau Peraturan
                              Kepala Daerah terkait penerbitan Sertifikat Laik
                              Fungsi melalui sistem elektronik?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Perda mengenai Retribusi Persetujuan Bangunan
                                  Gedung dalam lembaran daerah
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d5_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options3"
                                  style="min-width: 150px"
                                  v-model="datapm.p2d5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options3">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2d5_nilai',
                                      dataVerlap.p2d5_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2d5_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2d5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2d5_nilai_status
                                    ),
                                  }"
                                  :options="options3"
                                  v-model="dataQC.p2d5_nilai"
                                  @change="
                                    submitQC('p2d5_nilai', dataQC.p2d5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Perkada mengenai tata cara penyelenggaraan PBG
                                  dan Sertifikat Laik Fungsi (SLF)
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d5_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d5_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung dan
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, sudah disahkan” apabila
                                      seluruh ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada, belum disahkan” apabila
                                      seluruh ketentuan di atas tidak terpenuhi
                                      namun sudah tersedia dalam bentuk
                                      naskah/dokumen usulan.
                                      <br />
                                      3. Nilai “Belum ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">6</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi Perda atau Perkada
                              terkait penerbitan Sertifikat Laik Fungsi melalui
                              sistem elektronik?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian ada tidaknya sosialisasi Perda atau
                              Perkada terkait penerbitan Sertifikat Laik Fungsi
                              melalui sistem elektronik dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d6_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d6_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options7"
                                  style="min-width: 150px"
                                  v-model="datapm.p2d6_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options7">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2d6_nilai',
                                      dataVerlap.p2d6_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2d6_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2d6_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2d6_nilai_status
                                    ),
                                  }"
                                  :options="options7"
                                  v-model="dataQC.p2d6_nilai"
                                  @change="
                                    submitQC('p2d6_nilai', dataQC.p2d6_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d6_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d6_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d6_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d6_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d6_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d6_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2d6_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2d6_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2d6_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      Pasal 347 ayat (2) PP No. 16 Tahun 2021
                                      tentang Peraturan Pelaksanaan
                                      Undang-Undang Nomor 28 Tahun 2002 Tentang
                                      Bangunan Gedung, Pemerintah kabupaten/kota
                                      wajib menyediakan Persetujuan Bangunan
                                      Gedung (PBG) paling lama 6 (enam) bulan
                                      terhitung sejak PP No. 16 Tahun 2021
                                      diundangkan melalui penetapan:
                                      <div class="col-12">
                                        a. Peraturan daerah mengenai Retribusi
                                        Persetujuan Bangunan Gedung dan
                                      </div>
                                      <div class="col-12">
                                        b. Peraturan kepala daerah mengenai tata
                                        cara penyelenggaraan PBG dan Sertifikat
                                        Laik Fungsi (SLF) melalui Sistem
                                        Informasi Manajemen Bangunan Gedung
                                        (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, lebih dari 1 kali” apabila
                                      kelima ketentuan di atas terpenuhi.<br />
                                      2. Nilai “Ada 1 kali” apabila salah satu
                                      dari ketentuan di atas tidak terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi
                              kegiatan penyederhanaan persyaratan dasar
                              Perizinan Berusaha?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi kegiatan penyederhanaan persyaratan dasar
                              Perizinan Berusaha dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2e1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2e1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2e1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p2e1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2e1_nilai',
                                      dataVerlap.p2e1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2e1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2e1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2e1_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p2e1_nilai"
                                  @change="
                                    submitQC('p2e1_nilai', dataQC.p2e1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2e1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2e1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2e1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2e1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2e1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2e1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2e1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2e1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2e1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2e1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2e1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2e1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="color: white; text-align: justify"
                                  >
                                    <div class="col-12">
                                      Penyusunan Perda dan Perkada berdasarkan
                                      PP No. 6 Tahun 2021 tentang
                                      Penyelenggaraan Perizinan Berusaha di
                                      Daerah, PP No. 22 Tahun 2021 tentang
                                      Penyelenggaraan Perlindungan dan
                                      Pengelolaan Lingkungan Hidup, dan PP No.
                                      16 Tahun 2021 tentang Peraturan
                                      Pelaksanaan Undang-Undang Nomor 28 Tahun
                                      2002 Tentang Bangunan Gedung, Pemda wajib
                                      melakukan kegiatan monitoring dan evaluasi
                                      kegiatan penyederhanaan persyaratan dasar
                                      Perizinan Berusaha yakni:
                                      <div class="col-12">
                                        1. Perda mengenai Rencana Tata Ruang
                                        Wilayah (RTRW)
                                      </div>
                                      <div class="col-12">
                                        2. Perkada mengenai Rencana Detail Tata
                                        Ruang (RDTR)
                                      </div>
                                      <div class="col-12">
                                        3. Perkada mengenai pendelegasian
                                        kewenangan penyelenggaraan perizinan
                                        berusaha kepada Kepala DPMPTSP
                                      </div>
                                      <div class="col-12">
                                        4. Perkada mengenai pendelegasian
                                        kewenangan penyelenggaraan persetujuan
                                        lingkungan kepada Kepala DPMPTSP atau
                                        Kepala Dinas Lingkungan Hidup
                                      </div>
                                      <div class="col-12">
                                        5. Perda mengenai retribusi PBG
                                      </div>
                                      <div class="col-12">
                                        6. Perkada mengenai tata cara
                                        penyelenggaraan PBG dan Sertifikat Laik
                                        Fungsi (SLF) melalui Sistem Informasi
                                        Manajemen Bangunan Gedung (SIMBG)
                                      </div>
                                    </div>
                                    <p style="color: white">
                                      Untuk penetapan bobot penilaian: <br />
                                      1. Nilai “Ada, teragenda” apabila kelima
                                      ketentuan di atas terpenuhi<br />
                                      2. Nilai “Ada, tidak teragenda” apabila
                                      salah satu dari ketentuan di atas tidak
                                      terpenuhi.
                                      <br />
                                      3. Nilai “Tidak ada” apabila seluruh
                                      ketentuan di atas tidak terpenuhi.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan kinerja penyederhanaan
                              persyaratan dasar Perizinan Berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2e2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan kinerja
                                  penyederhanaan persyaratan dasar Perizinan
                                  Berusaha dilakukan dengan ketentuan apabila
                                  laporan kinerja penyederhanaan persyaratan
                                  dasar Perizinan Berusaha dibuat secara formal
                                  dalam bentuk dokumen laporan lengkap fisik
                                  atau digital, bukan bahan presentasi dan
                                  sejenisnya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2e2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2e2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2e2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p2e2_nilai',
                                      dataVerlap.p2e2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p2e2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2e2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2e2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p2e2_nilai"
                                  @change="
                                    submitQC('p2e2_nilai', dataQC.p2e2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_2_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_2_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_2_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_2_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Peningkatan Iklim
                          Investasi berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Verifikasi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Perangkat Pelaksana PPB</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada tim atau unit pelaksana PPB?
                            </td>
                            <td colspan="5">
                              Penilaian ketersediaan tim atau unit pelaksana PPB
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pemda memiliki dan dapat menunjukkan
                                  penetapan/keputusan dari Kepala Daerah
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3a1_nilai',
                                      dataVerlap.p3a1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3a1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3a1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p3a1_nilai"
                                  @change="
                                    submitQC('p3a1_nilai', dataQC.p3a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pemda memiliki ruang sekretariat organisasi
                                  tim internal Pemda yang melaksanakan tugas dan
                                  fungsi PPB
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">2</td>
                            <td rowspan="4">
                              Apakah ada program kerja PPB di daerah?
                            </td>
                            <td colspan="5">
                              Penilaian program kerja PPB di daerah dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pemda memiliki dan dapat menunjukkan bukti
                                  program kerja
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3a2_nilai',
                                      dataVerlap.p3a2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3a2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3a2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p3a2_nilai"
                                  @change="
                                    submitQC('p3a2_nilai', dataQC.p3a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pemda memiliki dan dapat menunjukkan bukti
                                  realisasi kegiatan program kerja
                                  (sekurang-kurangnya 50% dari program kerja
                                  yang direncanakan)
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada rapat koordinasi unit pelaksana PPB?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya rapat koordinasi teragenda
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 1 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3a3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3a3_nilai',
                                      dataVerlap.p3a3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3a3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3a3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3a3_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p3a3_nilai"
                                  @change="
                                    submitQC('p3a3_nilai', dataQC.p3a3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Koordinasi Formal Lintas Pemangku
                                kepentingan</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pusat?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Pusat dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 6 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options7"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options7">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3b1_nilai',
                                      dataVerlap.p3b1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3b1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b1_nilai_status
                                    ),
                                  }"
                                  :options="options7"
                                  v-model="dataQC.p3b1_nilai"
                                  @change="
                                    submitQC('p3b1_nilai', dataQC.p3b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pemerintah
                              Provinsi?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Provinsi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options7"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options7">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3b2_nilai',
                                      dataVerlap.p3b2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3b2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b2_nilai_status
                                    ),
                                  }"
                                  :options="options7"
                                  v-model="dataQC.p3b2_nilai"
                                  @change="
                                    submitQC('p3b2_nilai', dataQC.p3b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan daerah
                              lainnya?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya koordinasi formal dengan
                              daerah lainnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3b3_nilai',
                                      dataVerlap.p3b3_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3b3_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b3_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p3b3_nilai"
                                  @change="
                                    submitQC('p3b3_nilai', dataQC.p3b3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal lintas OPD?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya koordinasi formal lintas
                              OPD dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3b4_nilai',
                                      dataVerlap.p3b4_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3b4_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b4_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p3b4_nilai"
                                  @change="
                                    submitQC('p3b4_nilai', dataQC.p3b4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">5</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pemangku kepentingan lainnya
                              (Perbankan dan APH)?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya koordinasi pemangku
                              kepentingan lainnya (Perbankan dan APH) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b5_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3b5_nilai',
                                      dataVerlap.p3b5_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3b5_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b5_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p3b5_nilai"
                                  @change="
                                    submitQC('p3b5_nilai', dataQC.p3b5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b5_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b5_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b5_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b5_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b5_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b5_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b5_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b5_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b5_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b5_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b5_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi
                              implementasi PPB?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi implementasi PPB dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options6">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3c1_nilai',
                                      dataVerlap.p3c1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3c1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3c1_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  v-model="dataQC.p3c1_nilai"
                                  @change="
                                    submitQC('p3c1_nilai', dataQC.p3c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada tindak lanjut dan laporan hasil
                              monitoring dan evaluasi kegiatan PPB?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian keberadaan tindak lanjut dan laporan
                                  hasil monitoring dan evaluasi kegiatan PPB
                                  dilakukan dengan ketentuan apabila laporan
                                  hasil monitoring dan evaluasi kegiatan PPB
                                  dibuat secara formal dalam bentuk dokumen
                                  laporan lengkap fisik atau digital, bukan
                                  bahan presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3c2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3c2_nilai',
                                      dataVerlap.p3c2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3c2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3c2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3c2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p3c2_nilai"
                                  @change="
                                    submitQC('p3c2_nilai', dataQC.p3c2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Tindak lanjut atas hasil Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">1</td>
                            <td rowspan="6">
                              Apakah ada evaluasi hasil kinerja PPB tahun
                              sebelumnya?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya evaluasi hasil kinerja PPB
                              tahun sebelumnya dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia surat undangan resmi evaluasi hasil
                                  kinerja PPB tahun sebelumnya (melibatkan pihak
                                  eksternal) dalam bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3d1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3d1_nilai',
                                      dataVerlap.p3d1_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3d1_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3d1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3d1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p3d1_nilai"
                                  @change="
                                    submitQC('p3d1_nilai', dataQC.p3d1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Tersedia notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila keempat ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada penambahan alokasi anggaran (rutin)
                              pelayanan perizinan berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya penambahan alokasi
                                  anggaran (rutin) pelayanan perizinan berusaha
                                  dilakukan dengan ketentuan apabila ada
                                  penambahan alokasi anggaran (rutin) pelayanan
                                  perizinan berusaha
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3d2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <template v-for="(data,i) in options1">
                                  <b-button
                                  style="min-width: 150px"
                                  @click="
                                    submitQC(
                                      'p3d2_nilai',
                                      dataVerlap.p3d2_nilai
                                    )
                                  " :key="i" variant="secondary" v-if="data.value == dataVerlap.p3d2_nilai">{{data.text}}</b-button>
                                </template>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3d2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3d2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  v-model="dataQC.p3d2_nilai"
                                  @change="
                                    submitQC('p3d2_nilai', dataQC.p3d2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_3_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_3_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_3_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_3_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    style="margin-top: -40px"
                  >
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Bukti Verifikasi
                    </h4>

                    <div class="my-5">
                      <div class="card card-custom">
                        <div class="card-body">
                          <!--begin: Datatable-->
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                              >Nama Pendamping dari PTSP</label
                            >
                            <div class="col-lg-8">
                              <input
                                type="text"
                                v-model="dataQC.nama_pendamping"
                                class="form-control"
                                placeholder="Nama Pendamping dari PTSP"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                              >Nomor Hp Pendamping</label
                            >
                            <div class="col-lg-8">
                              <input
                                type="text"
                                v-model="dataQC.nomor_pendamping"
                                class="form-control"
                                v-on:input="filterInput"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                              >Lattitude</label
                            >
                            <div class="col-lg-8">
                              <input
                                type="text"
                                v-model="latitude"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                              >Longtitude</label
                            >
                            <div class="col-lg-8">
                              <input
                                type="text"
                                v-model="longtitude"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-lg-4 col-form-label"></label>
                            <div class="col-lg-8">
                              <div class="row">
                                <div class="col-xl-12">
                                  <!--begin::Input-->
                                  <div class="form-group">
                                    <label
                                      >Upload Foto Bersama Pendamping</label
                                    >
                                    <div></div>
                                    <b-form-file
                                      v-model="dataQC.fotoBukti"
                                      accept="image/*"
                                      type="file"
                                      ref="file"
                                    ></b-form-file>
                                  </div>
                                  <div v-if="dataQC.foto != null">
                                    <b-img
                                      :src="dataQC.foto"
                                      style="height: 100px"
                                      fluid
                                      alt="Foto"
                                    ></b-img>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Sebelumnya
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                        @click="simpanSementara()"
                      >
                        Simpan Sementera
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>
  
   
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-7.scss";
</style>
  
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";

import qs from "qs";

import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "ppbpemda-detail",
  mounted() {
    this.loadProfile()
    this.$forceUpdate();
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: localStorage.getItem("nama_wilayah")},
    //   { title: "PPB" },
    //   { title: "Quality Control" },
    // ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: false, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });
    this.loadQC();
    this.loadPM();
    this.loadVerlap();
    this.getlocation();
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      id_ptsp: this.$route.params.id,
      tahun: new Date().getFullYear(),
      user: JSON.parse(localStorage.getItem("user")),
      optionsPilihan: [
        { value: "Belum QC", text: "Belum QC" },
        { value: "Sesuai", text: "Sesuai" },
        { value: "Tidak sesuai", text: "Tidak sesuai" },
      ],
      options1: [
        { value: 25, text: "Ada" },
        { value: 0, text: "Tidak ada" },
      ],
      options2: [
        { value: 25, text: "Ada, lebih dari 1 kali" },
        { value: 10, text: "Ada 1 kali" },
        { value: 0, text: "Tidak ada" },
      ],
      options3: [
        { value: 25, text: "Ada, sudah disahkan" },
        { value: 10, text: "Ada, belum disahkan" },
        { value: 0, text: "Belum ada" },
      ],
      options4: [
        { value: 25, text: "Ada, lebih dari 1 kali" },
        { value: 10, text: "Ada, hanya 1 kali" },
        { value: 0, text: "Tidak ada" },
      ],
      options5: [
        { value: 25, text: "85 – 100%" },
        { value: 15, text: "70 – 84,99%" },
        { value: 10, text: "50 – 69,99%" },
        { value: 0, text: "dibawah 50%" },
      ],
      options6: [
        { value: 25, text: "Ada, teragenda" },
        { value: 10, text: "Ada, tidak teragenda" },
        { value: 0, text: "Tidak ada" },
      ],
      options7: [
        { value: 25, text: "Ada, lebih dari 1 kali" },
        { value: 15, text: "Ada, hanya 1 kali" },
        { value: 0, text: "Tidak ada" },
      ],
      dataVerlap: {},
      datapm: {},
      dataQC: {},
      location: null,
      gettingLocation: false,
      errorStr: null,
      latitude: "",
      longtitude: "",
      pm: {},
      fileInputKey: null,
      profil: {}
    };
  },
  methods: {
    filterInput(event) {
      const input = event.target.value;
      const filteredInput = input.replace(/[^0-9+\-()]/g, "");
      this.dataQC.nomor_pendamping = filteredInput;
    },
    warna(value) {
      if (value == "Sudah QC dengan perubahan") {
        return "blue";
      } else if (value == "Sudah QC") {
        return "green";
      } else {
        return "";
      }
    },
    warnaText(value) {
      if (value == "Sudah QC dengan perubahan") {
        return "white";
      } else if (value == "Sudah QC") {
        return "white";
      } else {
        return "black";
      }
    },
    loadProfile() {
      axios
        .get(this.url + `/profils?filter=idptsp,=,` + this.$route.params.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.profil = response.data.data;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.profil.daerah },
            { title: "PPB" },
            { title: "Quality Control" },
          ]);
        });
    },
    loadQC() {
      axios
        .get(
          this.url +
            "/qc_ppb_pemda_2023?filter=idptsp,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataQC = response.data.data[0];
        });
    },
    loadPM() {
      axios
        .get(
          this.url +
            "/pm_ppb_pemda_2023?filter=idptsp,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.datapm = response.data.data[0];
        });
    },

    loadVerlap() {
      axios
        .get(
          this.url +
            "/verifikasi_ppb_pemda_2023?filter=idptsp,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataVerlap = response.data.data[0];
          console.log(this.dataVerlap);
        });
    },
    simpanSementara() {
      var wizard = new KTWizard("kt_wizard_v4");

      //console.log(wizard.currentStep)
      if (wizard.currentStep === 1) {
        if (
          this.dataQC.p1a1_nilai_status != "Belum QC" &&
          this.dataQC.p1a2_nilai_status != "Belum QC" &&
          this.dataQC.p1a3_nilai_status != "Belum QC" &&
          this.dataQC.p1a4_nilai_status != "Belum QC" &&
          this.dataQC.p1a5_nilai_status != "Belum QC" &&
          this.dataQC.p1a6_nilai_status != "Belum QC" &&
          this.dataQC.p1b1_nilai_status != "Belum QC" &&
          this.dataQC.p1c1_nilai_status != "Belum QC" &&
          this.dataQC.p1c2_nilai_status != "Belum QC"
        ) {
          this.verifikasiFinal();
        } else {
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Silahkan isi semua form!",
          });
          wizard.stop();
        }
      } else if (wizard.currentStep === 2) {
        if (
          this.dataQC.p2a1_nilai_status != "Belum QC" &&
          this.dataQC.p2a2_nilai_status != "Belum QC" &&
          this.dataQC.p2a3_nilai_status != "Belum QC" &&
          this.dataQC.p2a4_nilai_status != "Belum QC" &&
          this.dataQC.p2a5_nilai_status != "Belum QC" &&
          this.dataQC.p2a6_nilai_status != "Belum QC" &&
          this.dataQC.p2b1_nilai_status != "Belum QC" &&
          this.dataQC.p2b2_nilai_status != "Belum QC" &&
          this.dataQC.p2b3_nilai_status != "Belum QC" &&
          this.dataQC.p2b4_nilai_status != "Belum QC" &&
          this.dataQC.p2b5_nilai_status != "Belum QC" &&
          this.dataQC.p2b6_nilai_status != "Belum QC" &&
          this.dataQC.p2c1_nilai_status != "Belum QC" &&
          this.dataQC.p2c2_nilai_status != "Belum QC" &&
          this.dataQC.p2c3_nilai_status != "Belum QC" &&
          this.dataQC.p2c4_nilai_status != "Belum QC" &&
          this.dataQC.p2c5_nilai_status != "Belum QC" &&
          this.dataQC.p2c6_nilai_status != "Belum QC" &&
          this.dataQC.p2d1_nilai_status != "Belum QC" &&
          this.dataQC.p2d2_nilai_status != "Belum QC" &&
          this.dataQC.p2d3_nilai_status != "Belum QC" &&
          this.dataQC.p2d4_nilai_status != "Belum QC" &&
          this.dataQC.p2d5_nilai_status != "Belum QC" &&
          this.dataQC.p2d6_nilai_status != "Belum QC" &&
          this.dataQC.p2e1_nilai_status != "Belum QC" &&
          this.dataQC.p2e2_nilai_status != "Belum QC"
        ) {
          this.verifikasiFinal();
        } else {
          wizard.stop();
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Silahkan isi semua form!",
          });
        }
      }
    },
    verifikasiFinal() {
      var wizard = new KTWizard("kt_wizard_v4");
      const idptsp = this.id_ptsp;
      const tahun = this.tahun;
      const url = this.url;
      const token = this.token;
      // Change event
      wizard.on("change", function (e) {
        const formData = {
          form: e.currentStep,
          idptsp: idptsp,
          tahun: tahun,
        };
        axios
          .put(url + "/qc_ppb_pemda_2023_final", qs.stringify(formData), {
            // _method: 'patch',
            headers: {
              xth: token,
              "content-type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          });
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 500);
      });
    },
    submit: function (e) {
      e.preventDefault();
      if (
        this.dataQC.p3a1_nilai_status != "Belum QC" &&
        this.dataQC.p3a2_nilai_status != "Belum QC" &&
        this.dataQC.p3a3_nilai_status != "Belum QC" &&
        this.dataQC.p3b1_nilai_status != "Belum QC" &&
        this.dataQC.p3b2_nilai_status != "Belum QC" &&
        this.dataQC.p3b3_nilai_status != "Belum QC" &&
        this.dataQC.p3b4_nilai_status != "Belum QC" &&
        this.dataQC.p3b5_nilai_status != "Belum QC" &&
        this.dataQC.p3c1_nilai_status != "Belum QC" &&
        this.dataQC.p3c2_nilai_status != "Belum QC" &&
        this.dataQC.p3d1_nilai_status != "Belum QC" &&
        this.dataQC.p3d2_nilai_status != "Belum QC"
      ) {
        Swal.fire({
          title: "Perhatian",
          text: "Apakah Anda ingin menyelesaikan dan mengirim hasil Quality Control?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = {
              form: 3,
              idptsp: this.id_ptsp,
              tahun: this.tahun,
            };
            axios
              .put(
                this.url + "/qc_ppb_pemda_2023_final",
                qs.stringify(formData),
                {
                  // _method: 'patch',
                  headers: {
                    xth: this.token,
                    "content-type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((response) => {
                Swal.fire(
                  "Berhasil",
                  "Terima kasih sudah menyelesaikan Quality Control",
                  "success"
                );
                if (localStorage.getItem("jenis") == "provinsi") {
                  this.$router.push("/qualitycontrol/ptsp-provinsi");
                } else if (localStorage.getItem("jenis") == "kabupaten") {
                  this.$router.push("/qualitycontrol/ptsp-kabupaten");
                } else if (localStorage.getItem("jenis") == "kota") {
                  this.$router.push("/qualitycontrol/ptsp-kota");
                }
                return response;
              })
              .catch((error) => {
                return error;
              });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Silahkan isi semua form!",
        });
      }
    },
    previewFiles(data) {
      // Form 1
      if (data == "p1a1_file1") {
        this.$refs.p1a1_file1.click();
      } else if (data == "p1a2_file1") {
        this.$refs.p1a2_file1.click();
      } else if (data == "p1a2_file2") {
        this.$refs.p1a2_file2.click();
      } else if (data == "p1a2_file3") {
        this.$refs.p1a2_file3.click();
      } else if (data == "p1a2_file4") {
        this.$refs.p1a2_file4.click();
      } else if (data == "p1a2_file5") {
        this.$refs.p1a2_file5.click();
      } else if (data == "p1a3_file1") {
        this.$refs.p1a3_file1.click();
      } else if (data == "p1a3_file2") {
        this.$refs.p1a3_file2.click();
      } else if (data == "p1a3_file3") {
        this.$refs.p1a3_file3.click();
      } else if (data == "p1a3_file4") {
        this.$refs.p1a3_file4.click();
      } else if (data == "p1a3_file5") {
        this.$refs.p1a3_file5.click();
      } else if (data == "p1a4_file1") {
        this.$refs.p1a4_file1.click();
      } else if (data == "p1a4_file2") {
        this.$refs.p1a4_file2.click();
      } else if (data == "p1a4_file3") {
        this.$refs.p1a4_file3.click();
      } else if (data == "p1a4_file4") {
        this.$refs.p1a4_file4.click();
      } else if (data == "p1a4_file5") {
        this.$refs.p1a4_file5.click();
      } else if (data == "p1a5_file1") {
        this.$refs.p1a5_file1.click();
      } else if (data == "p1a6_file1") {
        this.$refs.p1a6_file1.click();
      } else if (data == "p1a6_file2") {
        this.$refs.p1a6_file2.click();
      } else if (data == "p1a6_file3") {
        this.$refs.p1a6_file3.click();
      } else if (data == "p1a6_file4") {
        this.$refs.p1a6_file4.click();
      } else if (data == "p1a6_file5") {
        this.$refs.p1a6_file5.click();
      } else if (data == "p1b1_file1") {
        this.$refs.p1b1_file1.click();
      } else if (data == "p1b1_file2") {
        this.$refs.p1b1_file2.click();
      } else if (data == "p1c1_file1") {
        this.$refs.p1c1_file1.click();
      } else if (data == "p1c1_file2") {
        this.$refs.p1c1_file2.click();
      } else if (data == "p1c1_file3") {
        this.$refs.p1c1_file3.click();
      } else if (data == "p1c1_file4") {
        this.$refs.p1c1_file4.click();
      } else if (data == "p1c1_file5") {
        this.$refs.p1c1_file5.click();
      } else if (data == "p1c2_file1") {
        this.$refs.p1c2_file1.click();
      }

      // Form 2
      else if (data == "p2a1_file1") {
        this.$refs.p2a1_file1.click();
      } else if (data == "p2a1_file2") {
        this.$refs.p2a1_file2.click();
      } else if (data == "p2a2_file1") {
        this.$refs.p2a2_file1.click();
      } else if (data == "p2a2_file2") {
        this.$refs.p2a2_file2.click();
      } else if (data == "p2a2_file3") {
        this.$refs.p2a2_file3.click();
      } else if (data == "p2a2_file4") {
        this.$refs.p2a2_file4.click();
      } else if (data == "p2a2_file5") {
        this.$refs.p2a2_file5.click();
      } else if (data == "p2a3_file1") {
        this.$refs.p2a3_file1.click();
      } else if (data == "p2a3_file2") {
        this.$refs.p2a3_file2.click();
      } else if (data == "p2a3_file3") {
        this.$refs.p2a3_file3.click();
      } else if (data == "p2a3_file4") {
        this.$refs.p2a3_file4.click();
      } else if (data == "p2a3_file5") {
        this.$refs.p2a3_file5.click();
      } else if (data == "p2a4_file1") {
        this.$refs.p2a4_file1.click();
      } else if (data == "p2a4_file2") {
        this.$refs.p2a4_file2.click();
      } else if (data == "p2a4_file3") {
        this.$refs.p2a4_file3.click();
      } else if (data == "p2a4_file4") {
        this.$refs.p2a4_file4.click();
      } else if (data == "p2a4_file5") {
        this.$refs.p2a4_file5.click();
      } else if (data == "p2a5_file1") {
        this.$refs.p2a5_file1.click();
      } else if (data == "p2a5_file2") {
        this.$refs.p2a5_file2.click();
      } else if (data == "p2a6_file1") {
        this.$refs.p2a6_file1.click();
      } else if (data == "p2a6_file2") {
        this.$refs.p2a6_file2.click();
      } else if (data == "p2a6_file3") {
        this.$refs.p2a6_file3.click();
      } else if (data == "p2a6_file4") {
        this.$refs.p2a6_file4.click();
      } else if (data == "p2a6_file5") {
        this.$refs.p2a6_file5.click();
      } else if (data == "p2a6_file6") {
        this.$refs.p2a6_file6.click();
      } else if (data == "p2b1_file1") {
        this.$refs.p2b1_file1.click();
      } else if (data == "p2b2_file1") {
        this.$refs.p2b2_file1.click();
      } else if (data == "p2b2_file2") {
        this.$refs.p2b2_file2.click();
      } else if (data == "p2b2_file3") {
        this.$refs.p2b2_file3.click();
      } else if (data == "p2b2_file4") {
        this.$refs.p2b2_file4.click();
      } else if (data == "p2b2_file5") {
        this.$refs.p2b2_file5.click();
      } else if (data == "p2b3_file1") {
        this.$refs.p2b3_file1.click();
      } else if (data == "p2b3_file2") {
        this.$refs.p2b3_file2.click();
      } else if (data == "p2b3_file3") {
        this.$refs.p2b3_file3.click();
      } else if (data == "p2b3_file4") {
        this.$refs.p2b3_file4.click();
      } else if (data == "p2b3_file5") {
        this.$refs.p2b3_file5.click();
      } else if (data == "p2b4_file1") {
        this.$refs.p2b4_file1.click();
      } else if (data == "p2b4_file2") {
        this.$refs.p2b4_file2.click();
      } else if (data == "p2b4_file3") {
        this.$refs.p2b4_file3.click();
      } else if (data == "p2b4_file4") {
        this.$refs.p2b4_file4.click();
      } else if (data == "p2b4_file5") {
        this.$refs.p2b4_file5.click();
      } else if (data == "p2b5_file1") {
        this.$refs.p2b5_file1.click();
      } else if (data == "p2b6_file1") {
        this.$refs.p2b6_file1.click();
      } else if (data == "p2b6_file2") {
        this.$refs.p2b6_file2.click();
      } else if (data == "p2b6_file3") {
        this.$refs.p2b6_file3.click();
      } else if (data == "p2b6_file4") {
        this.$refs.p2b6_file4.click();
      } else if (data == "p2b6_file5") {
        this.$refs.p2b6_file5.click();
      } else if (data == "p2c1_file1") {
        this.$refs.p2c1_file1.click();
      } else if (data == "p2c2_file1") {
        this.$refs.p2c2_file1.click();
      } else if (data == "p2c2_file2") {
        this.$refs.p2c2_file2.click();
      } else if (data == "p2c2_file3") {
        this.$refs.p2c2_file3.click();
      } else if (data == "p2c2_file4") {
        this.$refs.p2c2_file4.click();
      } else if (data == "p2c2_file5") {
        this.$refs.p2c2_file5.click();
      } else if (data == "p2c3_file1") {
        this.$refs.p2c3_file1.click();
      } else if (data == "p2c3_file2") {
        this.$refs.p2c3_file2.click();
      } else if (data == "p2c3_file3") {
        this.$refs.p2c3_file3.click();
      } else if (data == "p2c3_file4") {
        this.$refs.p2c3_file4.click();
      } else if (data == "p2c3_file5") {
        this.$refs.p2c3_file5.click();
      } else if (data == "p2c4_file1") {
        this.$refs.p2c4_file1.click();
      } else if (data == "p2c4_file2") {
        this.$refs.p2c4_file2.click();
      } else if (data == "p2c4_file3") {
        this.$refs.p2c4_file3.click();
      } else if (data == "p2c4_file4") {
        this.$refs.p2c4_file4.click();
      } else if (data == "p2c4_file5") {
        this.$refs.p2c4_file5.click();
      } else if (data == "p2c5_file1") {
        this.$refs.p2c5_file1.click();
      } else if (data == "p2c6_file1") {
        this.$refs.p2c6_file1.click();
      } else if (data == "p2c6_file2") {
        this.$refs.p2c6_file2.click();
      } else if (data == "p2c6_file3") {
        this.$refs.p2c6_file3.click();
      } else if (data == "p2c6_file4") {
        this.$refs.p2c6_file4.click();
      } else if (data == "p2c6_file5") {
        this.$refs.p2c6_file5.click();
      } else if (data == "p2d1_file1") {
        this.$refs.p2d1_file1.click();
      } else if (data == "p2d1_file2") {
        this.$refs.p2d1_file2.click();
      } else if (data == "p2d2_file1") {
        this.$refs.p2d2_file1.click();
      } else if (data == "p2d2_file2") {
        this.$refs.p2d2_file2.click();
      } else if (data == "p2d2_file3") {
        this.$refs.p2d2_file3.click();
      } else if (data == "p2d2_file4") {
        this.$refs.p2d2_file4.click();
      } else if (data == "p2d2_file5") {
        this.$refs.p2d2_file5.click();
      } else if (data == "p2d3_file1") {
        this.$refs.p2d3_file1.click();
      } else if (data == "p2d3_file2") {
        this.$refs.p2d3_file2.click();
      } else if (data == "p2d3_file3") {
        this.$refs.p2d3_file3.click();
      } else if (data == "p2d3_file4") {
        this.$refs.p2d3_file4.click();
      } else if (data == "p2d3_file5") {
        this.$refs.p2d3_file5.click();
      } else if (data == "p2d4_file1") {
        this.$refs.p2d4_file1.click();
      } else if (data == "p2d4_file2") {
        this.$refs.p2d4_file2.click();
      } else if (data == "p2d4_file3") {
        this.$refs.p2d4_file3.click();
      } else if (data == "p2d4_file4") {
        this.$refs.p2d4_file4.click();
      } else if (data == "p2d4_file5") {
        this.$refs.p2d4_file5.click();
      } else if (data == "p2d5_file1") {
        this.$refs.p2d5_file1.click();
      } else if (data == "p2d5_file2") {
        this.$refs.p2d5_file2.click();
      } else if (data == "p2d6_file1") {
        this.$refs.p2d6_file1.click();
      } else if (data == "p2d6_file2") {
        this.$refs.p2d6_file2.click();
      } else if (data == "p2d6_file3") {
        this.$refs.p2d6_file3.click();
      } else if (data == "p2d6_file4") {
        this.$refs.p2d6_file4.click();
      } else if (data == "p2d6_file5") {
        this.$refs.p2d6_file5.click();
      } else if (data == "p2e1_file1") {
        this.$refs.p2e1_file1.click();
      } else if (data == "p2e1_file2") {
        this.$refs.p2e1_file2.click();
      } else if (data == "p2e1_file3") {
        this.$refs.p2e1_file3.click();
      } else if (data == "p2e1_file4") {
        this.$refs.p2e1_file4.click();
      } else if (data == "p2e1_file5") {
        this.$refs.p2e1_file5.click();
      } else if (data == "p2e2_file1") {
        this.$refs.p2e2_file1.click();
      }

      // Form 3
      else if (data == "p3a1_file1") {
        this.$refs.p3a1_file1.click();
      } else if (data == "p3a1_file2") {
        this.$refs.p3a1_file2.click();
      } else if (data == "p3a2_file1") {
        this.$refs.p3a2_file1.click();
      } else if (data == "p3a2_file2") {
        this.$refs.p3a2_file2.click();
      } else if (data == "p3a3_file1") {
        this.$refs.p3a3_file1.click();
      } else if (data == "p3a3_file2") {
        this.$refs.p3a3_file2.click();
      } else if (data == "p3a3_file3") {
        this.$refs.p3a3_file3.click();
      } else if (data == "p3a3_file4") {
        this.$refs.p3a3_file4.click();
      } else if (data == "p3a3_file5") {
        this.$refs.p3a3_file5.click();
      } else if (data == "p3b1_file1") {
        this.$refs.p3b1_file1.click();
      } else if (data == "p3b1_file2") {
        this.$refs.p3b1_file2.click();
      } else if (data == "p3b1_file3") {
        this.$refs.p3b1_file3.click();
      } else if (data == "p3b1_file4") {
        this.$refs.p3b1_file4.click();
      } else if (data == "p3b1_file5") {
        this.$refs.p3b1_file5.click();
      } else if (data == "p3b2_file1") {
        this.$refs.p3b2_file1.click();
      } else if (data == "p3b2_file2") {
        this.$refs.p3b2_file2.click();
      } else if (data == "p3b2_file3") {
        this.$refs.p3b2_file3.click();
      } else if (data == "p3b2_file4") {
        this.$refs.p3b2_file4.click();
      } else if (data == "p3b2_file5") {
        this.$refs.p3b2_file5.click();
      } else if (data == "p3b3_file1") {
        this.$refs.p3b3_file1.click();
      } else if (data == "p3b3_file2") {
        this.$refs.p3b3_file2.click();
      } else if (data == "p3b3_file3") {
        this.$refs.p3b3_file3.click();
      } else if (data == "p3b3_file4") {
        this.$refs.p3b3_file4.click();
      } else if (data == "p3b3_file5") {
        this.$refs.p3b3_file5.click();
      } else if (data == "p3b4_file1") {
        this.$refs.p3b4_file1.click();
      } else if (data == "p3b4_file2") {
        this.$refs.p3b4_file2.click();
      } else if (data == "p3b4_file3") {
        this.$refs.p3b4_file3.click();
      } else if (data == "p3b4_file4") {
        this.$refs.p3b4_file4.click();
      } else if (data == "p3b4_file5") {
        this.$refs.p3b4_file5.click();
      } else if (data == "p3b5_file1") {
        this.$refs.p3b5_file1.click();
      } else if (data == "p3b5_file2") {
        this.$refs.p3b5_file2.click();
      } else if (data == "p3b5_file3") {
        this.$refs.p3b5_file3.click();
      } else if (data == "p3b5_file4") {
        this.$refs.p3b5_file4.click();
      } else if (data == "p3b5_file5") {
        this.$refs.p3b5_file5.click();
      } else if (data == "p3c1_file1") {
        this.$refs.p3c1_file1.click();
      } else if (data == "p3c1_file2") {
        this.$refs.p3c1_file2.click();
      } else if (data == "p3c1_file3") {
        this.$refs.p3c1_file3.click();
      } else if (data == "p3c1_file4") {
        this.$refs.p3c1_file4.click();
      } else if (data == "p3c1_file5") {
        this.$refs.p3c1_file5.click();
      } else if (data == "p3c2_file1") {
        this.$refs.p3c2_file1.click();
      } else if (data == "p3d1_file1") {
        this.$refs.p3d1_file1.click();
      } else if (data == "p3d1_file2") {
        this.$refs.p3d1_file2.click();
      } else if (data == "p3d1_file3") {
        this.$refs.p3d1_file3.click();
      } else if (data == "p3d1_file4") {
        this.$refs.p3d1_file4.click();
      } else if (data == "p3d2_file1") {
        this.$refs.p3d2_file1.click();
      }
    },
    onFileChanged(e, data) {
      console.log(data);
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);

        this.$bvToast.toast("File terlalu besar, pilih file maksimal 500KB", {
          title: `Failed `,
          variant: `danger`,
          solid: true,
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        let formData = new FormData();
        formData.append(data, this.selectedFile);
        axios
          .post(
            this.url + "/verifikasi_ppb_pemda_2023/" + this.dataQC.id,
            formData,
            {
              // _method: 'patch',
              headers: {
                xth: this.token,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.$loading(false);
            this.$bvToast.toast(response.data.message, {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            this.loadVerlap();

            this.fileInputKey++;

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    submitNilaiRadio(data, value) {
      let formData = new FormData();
      formData.append(data, value);
      axios
        .post(
          this.url + "/verifikasi_ppb_pemda_2023/" + this.dataQC.id,
          formData,
          {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // this.$loading(false);
          // this.$bvToast.toast('Berhasil Verifikasi', {
          //     title: `Success `,
          //     variant: `success`,
          //     solid: true
          // })
          // console.log(response);
          this.loadVerlap();

          return response;
        })
        .catch((error) => {
          // console.log(error)
          // this.$loading(false);
          // this.$bvToast.toast('Gagal Verifikasi', {
          //     title: `Failed `,
          //     variant: `danger`,
          //     solid: true
          // })
          return error;
        });
    },

    submitQC(data, value) {
      let formData = new FormData();
      formData.append(data, value);
      axios
        .post(this.url + "/qc_ppb_pemda_2023/" + this.dataQC.id, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Quality Control", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(response);
          this.loadPM();
          this.loadVerlap();
          this.loadQC();

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$loading(false);
          this.$bvToast.toast("Gagal Quality Control", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },

    hapusFile(data) {
      // let formData = new FormData();
      // formData.append('field', data);
      // formData.append('idptsp', this.dataQC.idptsp);
      // formData.append('tahun', this.tahun);

      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak bisa mengembalikan file ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus File",
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = {
            field: data,
            idptsp: this.dataQC.idptsp,
            tahun: this.tahun,
          };
          axios
            .put(
              this.url + "/verifikasi_ppb_pemda_2023_delete_lampiran",
              qs.stringify(formData),
              {
                // _method: 'patch',
                headers: {
                  xth: this.token,
                  "content-type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((response) => {
              this.$loading(false);
              this.$bvToast.toast("Berhasil hapus file", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              // console.log(response);
              this.loadVerlap();

              return response;
            })
            .catch((error) => {
              console.log(error);
              this.$loading(false);
              this.$bvToast.toast("Gagal hapus file", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return error;
            });
        }
      });
    },
    getlocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          this.latitude = pos.coords.latitude;
          this.longtitude = pos.coords.longitude;
          console.log(this.latitude);
          console.log(this.longtitude);
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
  },
};
</script>
  
<style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>